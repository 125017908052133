import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {FormattedNumber} from 'react-intl'
import FormattedDateTime from './FormattedDateTime'
import IconWithCounter from '../iconWithCounter/IconWithCounter'
import Icon from '../icon/Icon'
import './Operation.css'

class Operation extends Component {
  downloadOperation(event, orgid, id){
    event.stopPropagation()
    window.open(`/api/organisations/${orgid}/operations/${id}?format=zip`)
  }

  render () {
    const { id, serial, name, startDate, endDate, images } = this.props.operation
    const allImages = this.props.images
    const numberOfCommentedImages = images.map(i => allImages[i]).reduce((prev, current) => { if (current.published) { return prev + 1 } else { return prev } }, 0)
    const orgid = this.props.orgid

    let formattedEndDate = ''
    if (endDate) {
      formattedEndDate = (<FormattedDateTime label='Einsatzende' date={endDate} />)
    }

    return (

        <div className='operation'>
          <Link to={`/organisations/${orgid}/operations/${id}`} className='metadata'>
            <div>
              #<FormattedNumber value={serial} format='serial' /> - {name} <br />
              <FormattedDateTime label='Einsatzbeginn' date={startDate} /> <br />
              {formattedEndDate}
            </div>
          </Link>
          <div className='item'>
            <IconWithCounter icon='fa-camera' counter={images.length} />
          </div>
          <div className='item share'>
            <IconWithCounter icon='fa-share' counter={numberOfCommentedImages} />
          </div>
          <div className='item download'>
            <Link title="Bilder vom Einsatz runterladen" to={""} onClick={(event) => this.downloadOperation(event, orgid, id)} >
              <Icon icon="fa-download" />
            </Link>
          </div>
        </div>

    )
  };
}

Operation.propTypes = {
  orgid: PropTypes.string.isRequired,
  operation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    serial: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    startDate: PropTypes.number.isRequired,
    endDate: PropTypes.number,
    images: PropTypes.array
  }).isRequired
}

function mapStateToProps (state) {
  return {
    'images': state.entities.images
  }
}

export default connect(mapStateToProps, {

})(Operation)
