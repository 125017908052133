import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { loadOrganisations } from '../actions';
import Organisation from '../components/organisation/Organisation';
import List from '../components/List';
import isEqual from 'lodash/isEqual';

class OrganisationsPage extends Component {

  componentDidMount() {
    this.props.loadOrganisations();
  }

  componentDidUpdate(prevProps){
    if (!isEqual(prevProps.organisations, this.props.organisations)) {
      this.props.loadOrganisations();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.organisations, this.props.organisations)) {
      this.props.loadOrganisations();
    }
  }

  handleLoadMoreClick() {
  }

  renderOrganisation(organisation){
    return(<Organisation organisation={organisation} key={organisation.id}/>);
  }

  render() {
    const { organisations } = this.props;
    if (!organisations) {
      return (<i>Lade Organisationen ...</i>);
    }

    return (
      <div>
        <List renderItem={this.renderOrganisation}
              items={organisations}
              onLoadMoreClick={this.handleLoadMoreClick}
              loadingLabel={`Keine Organisationen vorhanden.`}
               />
      </div>
    );
  }
}

OrganisationsPage.propTypes = {
  organisations: PropTypes.array.isRequired,
  loadOrganisations: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const {
    entities: { organisations }
  } = state;
  const organisations_array = Object.keys(organisations).map(key => organisations[key]);

 return {
   "organisations": organisations_array
 };
}

export default connect(mapStateToProps, {
  loadOrganisations
})(OrganisationsPage);
