import * as ActionTypes from '../actions'
import * as utils from '../utils/utils'
import merge from 'lodash/merge'
import {routerReducer} from 'react-router-redux'
import { combineReducers } from 'redux'
import jwt_decode from 'jwt-decode'

// Updates an entity cache in response to any action with response.entities.
function entities (state = { operations: {}, images: {}, comments: {}, organisations: {} }, action) {
  const {type, response, comment} = action
  // refactor to a switch if we handle more ActionTypes
  if (type === ActionTypes.TOGGLE_PUBLISHED_SUCCESS) {
    // just update the published status because the backend doesn't aend the image in this response
    state.images[response.result].published = response.entities.images[response.result].published
    return merge({}, state)
  } else if (type === ActionTypes.COMMENT_ADD) {
    state.comments[comment.id] = comment
    return merge({}, state)
  } else if (response && response.entities) {
    return merge({}, state, response.entities)
  }

  return state
}

// Updates error message to notify about the failed fetches.
function errorMessage (state = null, action) {
  const { type, error } = action
  if (type === ActionTypes.RESET_ERROR_MESSAGE) {
    return null
  } else if (error) {
    return action.error
  }

  return state
}

function application (state = {token: '', locale: 'de', user: {}}, action) {
  if (ActionTypes.LOGIN_SUCCESS === action.type) {
    const cookie = utils.readCookie('access_token')
    const stateFromToken = jwt_decode(cookie)
    return merge({}, state, {token: cookie, locale: 'de', user: stateFromToken})
  }
  return state
}

// Updates the pagination data for different actions.
// const pagination = combineReducers();

const rootReducer = combineReducers({
  entities,
  application,
  errorMessage,
  router: routerReducer
})

export default rootReducer
