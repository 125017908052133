import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {FormattedDate, FormattedTime, FormattedNumber} from 'react-intl'
import CommentList from '../comment/CommentList'
import IconWithCounter from '../iconWithCounter/IconWithCounter'
import Icon from '../icon/Icon'
import Loading from '../loading/Loading'
import * as utils from '../../utils/utils'
import './Image.css'

class Image extends Component {
  renderCommentList () {
    if (this.props.image.commentsEnabled) {
      return (<CommentList imageId={this.props.image.id} />)
    }
  }

  renderInfoBoxes (numberOfComments, published, contentType) {
    const isImage = function (contentType) {
      return contentType === 'image/jpeg' || contentType === 'image/png'
    }

    const commentsIcon = (<div className='image_icon'>
      <IconWithCounter icon='fa-comments-o' counter={numberOfComments} />
    </div>)
    const publishedIcon = (<div className='image_icon'>
      <Icon icon='fa-share' />
    </div>)
    const imageIcon = (<div className='image_icon'>
      <Icon icon='fa-camera' />
    </div>)
    const movieIcon = (<div className='image_icon'>
      <Icon icon='fa-video-camera' />
    </div>)

    const mediaIcon = isImage(contentType) ? imageIcon : movieIcon

    if (published) {
      return (<div className='image_infoBox'>
        {publishedIcon}
        {commentsIcon}
        {mediaIcon}
      </div>)
    } else {
      return (<div className='image_infoBox'>
        {commentsIcon}
        {mediaIcon}
      </div>)
    }
  }

  render () {
    if (!this.props.image) {
      return (<Loading />)
    }
    const {id, recordingTime, serial, cameraName, comments, published, contentType} = this.props.image
    const idValue = `image-${id}`
    if (!id) {
      return (<div className='image_thumbnail'><Loading /></div>)
    }
    return (<div className='image_thumbnail'>
      <Link to={`/organisations/${this.props.orgid}/operations/${this.props.opid}/images/${id}`}>
        <img id={idValue} className='image_image' src={`/api/images/${id}/thumbnail`} alt={recordingTime} />
        {this.renderInfoBoxes(comments.length, published, contentType)}
      </Link>
      <p className='image_info'>
        Aufnahme <FormattedNumber value={serial} format='serial' /> - {cameraName} <br />
        <strong><FormattedTime value={recordingTime} format='hhmmss' /></strong> - <FormattedDate value={recordingTime} format='short' />
      </p>
    </div>)
  }
}

Image.propTypes = {
  orgid: PropTypes.string.isRequired,
  opid: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  image: PropTypes.object.isRequired
}

function mapStateToProps (state, ownProps) {
  const route = utils.parseUrl(state.router.location.pathname)
  const opid = route['operations']
  var orgid = route['organisations']
  if (orgid === undefined || orgid === '') {
    orgid = state.application.user.organisation.id.toString()
  }
  const image = state.entities.images[ownProps.id]
  return {
    'orgid': orgid,
    'opid': opid,
    'image': image
  }
}

export default connect(mapStateToProps, {
})(Image)
