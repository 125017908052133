import { Component, isValidElement, Children, createElement } from 'react'
import Offline from './Offline'

const ping = ({ url, timeout }) => {
  return new Promise(resolve => {
    const isOnline = () => resolve(true)
    const isOffline = () => resolve(false)

    const xhr = new XMLHttpRequest()

    xhr.onerror = isOffline
    xhr.ontimeout = isOffline
    xhr.onload = () => {
      if (xhr.status === 200) {
        isOnline()
      } else {
        isOffline()
      }
    }

    xhr.open('GET', url)
    xhr.timeout = timeout
    xhr.send()
  })
}



export class Online extends Component {
  constructor () {
    super()
    this.state = {
      online: true
    }
  }

  render () {
    return this.state.online ? this.renderChildren() : Offline()
  }

  renderChildren () {
    const { children } = this.props

    // usual case: one child that is a react Element
    if (isValidElement(children)) {
      return children
    }

    // no children
    if (!children) {
      return null
    }

    // string children, multiple children, or something else
    return createElement('div', {}, ...Children.toArray(children))
  }

  componentDidMount () {
    ping({ url: '/api/health', timeout: 1000 }).then(online => {
      console.log(online)
      online ? this.goOnline() : this.goOffline()
    })
    // then repeat every 30 seconds
    this.startPolling(30000)
  }

  componentWillUnmount () {
    if (this.pollingId) {
      this.stopPolling()
    }
  }

  goOnline () {
    if (!this.state.online) {
      this.setState({ online: true })
    }
  }

  goOffline () {
    if (this.state.online) {
      this.setState({ online: false })
    }
  }

  startPolling (pause) {
    this.pollingId = setInterval(() => {
      ping({ url: '/api/health', timeout: 500 }).then(online => {
        online ? this.goOnline() : this.goOffline()
      })
    }, pause)
  }

  stopPolling () {
    clearInterval(this.pollingId)
  }
}
