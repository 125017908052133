import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedTime, FormattedDate } from 'react-intl'
import './Comment.css'

export default class Comment extends Component {

  renderComment (comment) {
    return comment.split('\n').map((item, key) => {
      return <span key={key}>{item}<br /></span>
    })
  }

  render () {
    const {createdAt, content, author} = this.props
    return (
      <div className='Comment_comment'>
        <div className='Comment_meta'><strong>{author}</strong> <FormattedDate value={createdAt} format='short' />&nbsp;<FormattedTime value={createdAt} format='hhmmss' /></div>
        <div className='Comment_content'>{this.renderComment(content)}</div>
      </div>)
  }
}

Comment.propTypes = {
  createdAt: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired
}
