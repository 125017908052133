import React, { Component } from 'react'

import { connect } from 'react-redux'
import NotAuthorized from './NotAuthorized'
import intersection from 'lodash/intersection'

export default function requireAuthorization (Element, roles) {
  class AuthorizedComponent extends Component {

    checkAuthorization () {
      if (!this.props.roles || intersection(this.props.roles, roles).length === 0) {
        return false
      } else {
        return true
      }
    }

    render () {
        // render the component that requires auth (passed to this wrapper)
      return (
        <div>
          {this.checkAuthorization() ? <Element {...this.props} /> : <NotAuthorized /> }
        </div>
      )
    }
  }

  const mapStateToProps =
    (state) => ({
      roles: state.application.user.roles
    })

  return connect(mapStateToProps)(AuthorizedComponent)
}
