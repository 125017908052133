import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import { IntlProvider, addLocaleData } from 'react-intl'
import deLocaleData from 'react-intl/locale-data/de'
import routes from '../routes'
import { renderRoutes } from 'react-router-config'
import { Online } from '../components/online/Online'
import { Backup } from '../components/backup/Backup'

// Lokaliserung hinzufügen
addLocaleData(deLocaleData)

export default class Root extends Component {
  render () {
    const { store, history } = this.props
    const intlData = {
      'locale': store.getState().application.locale,
      'formats': {
        'number': {
          'serial': {
            'minimumIntegerDigits': 3
          }
        },
        'date': {
          'short': {
            'day': '2-digit',
            'month': '2-digit',
            'year': 'numeric'
          },
          'day': {
            'weekday': 'short'
          }
        },
        'time': {
          'hhmm': {
            'hour': 'numeric',
            'minute': 'numeric'
          },
          'hhmmss': {
            'hour': 'numeric',
            'minute': 'numeric',
            'second': 'numeric'
          }
        }
      }
    }

    return (
      <Online>
        <Backup />
        <Provider store={store}>
          <IntlProvider key='intl' {...intlData}>
            <ConnectedRouter history={history}>
              {renderRoutes(routes)}
            </ConnectedRouter>
          </IntlProvider>
        </Provider>
      </Online>
    )
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
