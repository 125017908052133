import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import './Organisation.css'

export default class Organisation extends Component {
  render () {
    const { id, logo, name } = this.props.organisation

    return (
      <div className='organisation'>
        {logo ? <img src={`data:image/jpeg;base64,${logo}`} alt={name} /> : <div className='noLogo' />}&nbsp;
        {name}&nbsp;
        <Link to={`/organisations/${id}`}>Details</Link>
      </div>
    )
  };
}

Organisation.propTypes = {
  organisation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired
  }).isRequired
}
