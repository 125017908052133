import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from '../components/header/Header'
import OperationsPage from './OperationsPage'
import OrganisationsPage from './OrganisationsPage'
import { loadOrganisation } from '../actions'

class Dashboard extends Component {
  componentWillReceiveProps (nextProps) {
  }
  /* Render firefighter or consultant Dashboard */
  render () {
    return (<div>
      <Header />
      {this.props.user.roles.includes('firefighter') ? <OperationsPage /> : null}
      {this.props.user.roles.includes('consultant') ? <OrganisationsPage /> : null}
    </div>)
  }
}

function mapStateToProps (state) {
  const {
    'application': {user}
  } = state

  return {
    errorMessage: state.errorMessage,
    inputValue: '1',
    user: user
  }
}

export default connect(mapStateToProps, {
  loadOrganisation
})(Dashboard)
