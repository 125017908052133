import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './SquareButton.css'

const SquareButton = function (props) {
  let btnClass = classNames({
    'SquareButton': true,
    'active': props.active,
    'comment': props.comment,
    'cssInActive': props.cssInActive,
    'invisible': props.invisible,
  })
  return (<button className={btnClass} value={props.value} onClick={props.onClick}>{props.children}</button>)
}

export default SquareButton

SquareButton.propTypes = {
  // value: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  comment: PropTypes.bool, // is a comment button --> other css styleing
  cssInActive: PropTypes.bool, // prevents the :active state on this button
  invisible: PropTypes.bool, // hide this button
}
