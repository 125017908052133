import { normalize, schema } from 'normalizr'
import { camelizeKeys } from 'humps'
import 'isomorphic-fetch'

const API_ROOT = '/api/'

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
function callApi (endpoint, schema, options) {
  const fullUrl = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint

  return fetch(fullUrl, options)
    .then(response =>
      response.json().then(json => ({ json, response }))
    ).then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(json)
      }

      const camelizedJson = camelizeKeys(json)

      return Object.assign({},
        normalize(camelizedJson, schema)
      )
    })
}

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/gaearon/normalizr

const organisationSchema = new schema.Entity('organisations', {
  idAttribute: 'id'
})

const userSchema = new schema.Entity('users', {
  idAttribute: 'username'
})

const operationSchema = new schema.Entity('operations', {
  idAttribute: 'id'
})

const imageSchema = new schema.Entity('images', {
  idAttribute: 'id'
})

const commentSchema = new schema.Entity('comments', {
  idAttribute: 'id'
})

const credentialSchema = new schema.Entity('credentials', {}, {
  idAttribute: (entity) => { return 'credential' } // always reply with constant id
})

operationSchema.define({
  images: [imageSchema]
})

imageSchema.define({
  operation: operationSchema,
  comments: [commentSchema]
})

// Schemas for API responses.
export const Schemas = {
  USER: userSchema,
  USER_ARRAY: [userSchema],
  ORGANISATION: organisationSchema,
  ORGANISATION_ARRAY: [organisationSchema],
  OPERATION: operationSchema,
  OPERATION_ARRAY: [operationSchema],
  CREDENTIAL: credentialSchema,
  IMAGE: imageSchema,
  IMAGES: [imageSchema],
  THUMBNAIL: imageSchema,
  THUMBNAILS: [imageSchema],
  COMMENT: commentSchema,
  COMMENTS: [commentSchema]
}

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API')

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
  const callAPI = action[CALL_API]
  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  let { endpoint, method } = callAPI
  const { schema, types, body } = callAPI

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState())
  }
  if (!method) {
    method = 'GET'
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }
  if (!schema) {
    throw new Error('Specify one of the exported Schemas.')
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.')
  }

  function actionWith (data) {
    const finalAction = Object.assign({}, action, data)
    delete finalAction[CALL_API]
    return finalAction
  }

  const [ requestType, successType, failureType ] = types
  next(actionWith({ type: requestType }))

  const options = {
    'method': method,
    'body': JSON.stringify(body),
    'headers': {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    'credentials': 'same-origin'
  }

  return callApi(endpoint, schema, options).then(
    response => next(actionWith({
      response,
      type: successType
    })),
    error => next(actionWith({
      type: failureType,
      error: error.message || 'Could not get an error message from remote.'
    }))
  )
}
