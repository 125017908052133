import React from 'react'
import './Offline.css'

const Offline = function (props) {
  return (<div>
    <div className='logo' />
    <div className='maintenance'>
      <div>The backend is currently not available. Please wait.</div>
      <div className='lds-roller'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  </div>)
}

export default Offline
