export function parseUrl (path) {
  const regex = /([a-z]+)\/(\d+)/ig
  let m = regex.exec(path)
  const route = {}
  while (m !== null) {
    route[m[1]] = m[2]
    m = regex.exec(path)
  }
  return route
}

export function readCookie (name) {
  const value = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')
  return value ? value.pop() : ''
}

export function isSecure (protocol) {
  return protocol.indexOf('https://') === 0
}

export function takeWhile (source, predicate) {
  var result = []
  var length = source.length
  var i = 0

  while (i < length && predicate(source[i], i)) {
    result.push(source[i])
    i++
  }

  return result
}

export function scrollToLeft (element, to, duration) {
  const start = element.scrollLeft
  const change = to - start
  let currentTime = 0
  const increment = 20

  var animateScroll = function () {
    currentTime += increment
    const val = easeInOutQuad(currentTime, start, change, duration)
    element.scrollLeft = val
    if (currentTime < duration) {
      setTimeout(animateScroll, increment)
    }
  }
  animateScroll()
}

// t = current time
// b = start value
// c = change in value
// d = duration
function easeInOutQuad (t, b, c, d) {
  t /= d / 2
  if (t < 1) {
    return c / 2 * t * t + b
  }
  t--
  return -c / 2 * (t * (t - 2) - 1) + b
}
