import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {FormattedDate, FormattedTime} from 'react-intl'
import { connect } from 'react-redux'
import Loading from '../loading/Loading'
import { loadOrganisation } from '../../actions'
import classNames from 'classnames'
import SquareButton from '../squareButton/SquareButton'
import Icon from '../icon/Icon'
import './Header.css'

class Header extends Component {
  constructor () {
    super()
    this.timerID = 0
    this.state = { date: new Date(), menu: false }
    this.toggleMenu = this.toggleMenu.bind(this)
    this.reload = this.reload.bind(this)
  }

  componentDidMount () {
    this.props.loadOrganisation(this.props.organisation.id, ['name', 'logo'])
    this.timerID = setInterval(() =>
      this.setState({ date: new Date() })
    , 1000)
  }

  componentWillUnmount () {
    clearInterval(this.timerID)
  }

  loading () {
    return (<Loading />)
  }

  finished (logo) {
    if (logo !== '') {
      return (<img src={`data:image/jpeg;base64,${logo}`} alt={this.name} />)
    } // TODO: Evtl. generisches Logo einblenden
  }

  toggleMenu (evt) {
    evt.preventDefault()
    if (this.state.menu) {
      this.setState({menu: false})
    } else {
      this.setState({menu: true})
    }
  }

  reload () {
    window.location.reload(true)
  }

  render () {
    const { logo } = this.props.organisation
    const fullName = this.props.fullName

    const logoutClass = classNames({'Header_logout_visible': this.state.menu, 'Header_logout_invisible': !this.state.menu})
    const pointerStyle = {
      cursor: 'pointer'
    }

    return (
      <div className='Header_organisation'>
        <div className='Header_logoAndName'>
          <div style={pointerStyle} onClick={this.reload} className='Header_logo'>{typeof logo === 'string' ? this.finished(logo) : this.loading()}</div>
        </div>
        <div className='Header_clock' onClick={this.reload}><FormattedTime value={this.state.date} format='hhmmss' /></div>
        <div className='Header_userAndMenu'>
          <div className='Header_menu'>
            <SquareButton onClick={this.toggleMenu}>
              <Icon icon='fa-bars' />
            </SquareButton>
            <Link className={logoutClass} to={`/logout/`}>
              <Icon icon='fa-power-off' >&nbsp;<span>Abmelden</span></Icon>
            </Link>
          </div>
          <div className='Header_dateUser'><strong><FormattedDate value={this.state.date} format='day' />&nbsp;<FormattedDate value={this.state.date} format='short' /></strong> <br /> {fullName}</div>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  organisation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  fullName: PropTypes.string.isRequired
}

function mapStateToProps (state) {
  const {
    'entities': { organisations },
    'application': {user}
  } = state

  const userOrganisation = organisations[state.application.user.organisation.id] || state.application.user.organisation
  return {
    organisation: userOrganisation,
    fullName: user.firstname + ' ' + user.lastname
  }
}

export default connect(mapStateToProps, {
  loadOrganisation
})(Header)
