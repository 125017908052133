import React from 'react'
import PropTypes from 'prop-types'
import './Icon.css'
import classNames from 'classnames'

const Icon = function (props) {
  const icons = classNames('fa', props.icon)
  return (<div className='icon_icon'><i className={icons} aria-hidden='true' />{props.children}</div>)
}

export default Icon

Icon.propTypes = {
  icon: PropTypes.string.isRequired
}
