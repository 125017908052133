import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import {routerMiddleware} from 'react-router-redux'
import api from '../middleware/api'
import rootReducer from '../reducers'

export default function configureStore (initialState, history) {
  const finalCreateStore = compose(
    applyMiddleware(thunk, api),
    applyMiddleware(routerMiddleware(history))
  )(createStore)

  const store = finalCreateStore(rootReducer, initialState)
  return store
}
