import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './Comment.css'

class CommentInput extends Component {

  constructor (props) {
    super(props)
    this.state = {
      text: '',
      imageId: props.id
    }
  }

  // This will be called when the user clicks on the post button
  postComment (evt) {
    evt.preventDefault()
    this.props.sendAction(this.state)
    this.setState({text: ''})
  }

  handleInputChange (evt) {
    this.setState({
      [evt.target.name]: evt.target.value,
      'createdAt': new Date().toISOString()
    })
  }

  render () {
    return (<div className='Comment_commentForm'>
      <form onSubmit={this.postComment.bind(this)} onChange={this.handleInputChange.bind(this)}>
        <textarea value={this.state.text} name='text' rows='1' cols='50' placeholder='Hier Ihre Nachricht eingeben.' />
        <div className='Comment_buttons'>
          <button className='Comment_submit' type='submit'>absenden</button>
        </div>
      </form>
    </div>)
  }
}

CommentInput.propTypes = {
  content: PropTypes.string,
  author: PropTypes.string,
  id: PropTypes.number.isRequired,
  sendAction: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    author: state.application.user.firstname + ' ' + state.application.user.lastane
  }
}

export default connect(mapStateToProps, {})(CommentInput)
