import React, {Component} from 'react'
import { connect } from 'react-redux'
import { redirect, doLogout } from '../actions'

class Logout extends Component {
  componentWillMount () {
    const result = this.props.doLogout()
    result.then(() =>
      this.props.redirect('/login')
    )
  }

  render () {
    return (<h1>Leaving the page.</h1>)
  }
}

function mapStateToProps (state) {
  return {}
}

export default connect(mapStateToProps, {
  doLogout,
  redirect
})(Logout)
