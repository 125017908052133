import 'babel-polyfill'
import 'intersection-observer'
import React from 'react'
import ReactDOM from 'react-dom'
import jwt_decode from 'jwt-decode'
import createHistory from 'history/createBrowserHistory'
//import registerServiceWorker from './registerServiceWorker'
import Root from './containers/Root'
import configureStore from './store/configureStore'
import * as utils from './utils/utils'
import './index.css'
import './styles/normalize.css'
import 'font-awesome/css/font-awesome.css'
import './styles/ubuntu-fonts.css'

let stateFromToken = { firstname: '', lastname: '', sub: '', organisation: { id: 0, name: '' }, roles: [] }
try {
  stateFromToken = jwt_decode(utils.readCookie('access_token'))
} catch (e) {
  console.info('Found no access_token within the cookies. Assuming user is not logged in.')
  // there is nothing we can do here
}

const initialState = {
  application: {
    token: utils.readCookie('access_token'),
    locale: 'de',
    user: stateFromToken
  }
}

const history = createHistory()

const store = configureStore(initialState, history)

ReactDOM.render(<Root store={store} history={history} />, document.getElementById('root'))
//registerServiceWorker()
