/* global WebSocket */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Comment from './Comment'
import CommentInput from './CommentInput'
import { addComment } from '../../actions'
import * as utils from '../../utils/utils'
import './Comment.css'

class CommentList extends Component {
  constructor (props) {
    super()
    // init websocket connection here
    let host = window.location.host ? window.location.host : 'localhost:3000'
    let protocol = utils.isSecure(window.location.href) ? 'wss://' : 'ws://'
    this.websocket = new WebSocket(protocol + host + '/ws/comments/' + props.imageId)

    this.websocket.onerror = (errorEvent) => {
      // TODO: Errorhandling
      console.log('Error! Die Verbindung wurde unerwartet geschlossen')
      console.log(errorEvent)
    }

    this.websocket.onclose = (closeEvent) => {
      console.log('Closed the websocket --- Code: ' + closeEvent.code + ' --- Reason: ' + closeEvent.reason)
      if (closeEvent.code !== 4999) {
        console.log('Trying to reconnect websocket.')
        this.websocket = new WebSocket(protocol + host + '/ws/comments/' + props.imageId)
      }
    }

    this.websocket.onmessage = (messageEvent) => {
      let comment = JSON.parse(messageEvent.data)
      props.onAddComment(comment)
    }
  }

  componentWillUnmount () {
    this.websocket.close(4999, 'componentWillUnmount triggered.')
  }

  send (message) {
    this.websocket.send(JSON.stringify(message))
  }

  renderComment (comment) {
    return (<Comment createdAt={comment.createdAt} content={comment.text} author={comment.author} key={comment.id} />)
  }

  render () {
    const id = this.props.imageId
    const comments = this.props.comments.map(comment => (this.renderComment(comment)))
    return (
      <div>
        <div className='Comment_header'>
          Kommentare
        </div>
        <div className='Comment_list'>
          {comments}
        </div>
        <CommentInput id={id} sendAction={this.send.bind(this)} />
      </div>)
  }
}

CommentList.propTypes = {
  imageId: PropTypes.number.isRequired,
  recievingTime: PropTypes.number.isRequired
}

function mapStateToProps (state, ownProps) {
  const allComments = Object.keys(state.entities.comments).map(key => state.entities.comments[key])
  const comments = allComments.filter((value) => (value.imageId === ownProps.imageId)).sort((a, b) => (a.createdAt - b.createdAt)).reverse()
  return {'comments': comments}
}

const mapDispatchToProps = dispatch => {
  return {
    onAddComment: comment => {
      dispatch(addComment(comment))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentList)
