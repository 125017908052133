import React, { PureComponent } from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Observer from 'react-intersection-observer'
import {FormattedNumber, FormattedDate, FormattedTime} from 'react-intl'
import { loadOperation, updateOperationName, endOperation } from '../actions'
import Header from '../components/header/Header'
import Row from '../components/row/Row'
import Image from '../components/image/Image'
import IconWithCounter from '../components/iconWithCounter/IconWithCounter'
import Icon from '../components/icon/Icon'
import SquareButton from '../components/squareButton/SquareButton'
import { Link } from 'react-router-dom'
import * as utils from '../utils/utils'
import classNames from 'classnames'
import '../styles/main.css'

class OperationDetailPage extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {editName: false, showModal: false}
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.endOperation = this.endOperation.bind(this)
    this.imageId = []
    // init websocket connection here
    let host = window.location.host ? window.location.host : 'localhost:3000'
    let protocol = utils.isSecure(window.location.href) ? 'wss://' : 'ws://'
    this.websocket = new WebSocket(protocol + host + '/ws/operations/' + props.id)

    this.websocket.onerror = (errorEvent) => {
      // TODO: Errorhandling
      console.log('Error! Die Verbindung wurde unerwartet geschlossen')
      console.log(errorEvent)
    }

    this.websocket.onclose = (closeEvent) => {
      console.log('Closed the websocket --- Code: ' + closeEvent.code + ' --- Reason: ' + closeEvent.reason)
    }

    this.websocket.onmessage = (messageEvent) => {
      // not the best version, but sufficent at the moment
      props.loadOperation(props.orgid, props.id)
      const imageId = this.imageId.sort().reverse().pop()
      this.setState({imageId: imageId})
    }
  }

  componentDidMount () {
    this.props.loadOperation(this.props.orgid, this.props.id)
  }

  componentDidUpdate (prevProps) {
    this.imageId = []
    const moveElementInView = (element, elementId) => {
      console.debug('Moving elememnt into view:')
      console.debug(element)
      // get width until element
      const idWidthPairs = Array.from(document.getElementsByClassName('image_thumbnail')).map(element => { return {id: element.children[0].children[0].id, width: element.offsetWidth} })
      const elementsBefore = utils.takeWhile(idWidthPairs, (x, i) => x.id !== elementId)
      const offset = elementsBefore.reduce((acc, val) => {
        return acc + val.width
      }, 0)
      utils.scrollToLeft(document.getElementById('imagerow'), offset + 25, 250)
    }

    setTimeout(() => {
      if (this.props.anchor) {
        let element = document.getElementById(this.props.anchor)
        if (element) {
          moveElementInView(element, this.props.anchor)
        }
      }
      if (this.state.imageId) {
        let element = document.getElementById(this.state.imageId)
        if (element) {
          moveElementInView(element, this.state.imageId)
        }
      }
    }, 100)
  }

  componentWillUnmount () {
    this.websocket.close(1000, 'componentWillUnmount triggered.')
  }

  toggleEdit (evt) {
    evt.preventDefault()
    this.setState({editName: !this.state.editName})
  }

  handleInputChange (evt) {
    this.setState({
      [evt.target.name]: evt.target.value
    })
  }

  handleOpenModal () {
    this.setState({ showModal: true })
  }

  handleCloseModal () {
    this.setState({ showModal: false })
  }

  endOperation (evt) {
    this.props.endOperation(this.props.orgid, this.props.id)
  }

  updateOperationName (evt) {
    evt.preventDefault()
    const updatedOperation = {name: this.state.operationName}
    this.props.updateOperationName(this.props.orgid, this.props.id, updatedOperation)
    this.props.operation.name = this.state.operationName
    this.toggleEdit(evt)
  }

  renderImages (image) {
    const callback = inView => {
      if (inView) {
        this.imageId.push(`image-${image.id}`)
      }
    }
    return (<Observer className='observable_image_thumbnail' threshold='1' onChange={callback} key={image.id} ><Image opid={image.operationId} id={image.id} /></Observer>)
  }

  render () {
    const { operation, images } = this.props
    const numberOfCommentedImages = images.reduce((prev, current) => { if (current.published) { return prev + 1 } else { return prev } }, 0)

    if (!operation) {
      return (<h1><i>Lade Einsatzdetails ...</i></h1>)
    }

    let endOperationButton = ''
    if (!operation.endDate) {
      endOperationButton = (<div className='edit'>
        <SquareButton onClick={this.handleOpenModal} active={false}>
          <Icon icon='fa-hourglass-end' />
        </SquareButton>
        <ReactModal
          isOpen={this.state.showModal}
          onRequestClose={this.handleCloseModal}
          className='modal'
          overlayClassName='overlay'
          contentLabel='Einsatz beenden?'
          ariaHideApp={false}>
          <p>Einsatz beenden?</p>
          <button onClick={this.handleCloseModal}>Abbrechen</button>
          <button onClick={this.endOperation}>Ja</button>
        </ReactModal>
      </div>)
    }

    let operationName = <span>{operation.name}</span>
    if (this.state.editName) {
      operationName =
      (<form onSubmit={this.updateOperationName.bind(this)} onChange={this.handleInputChange.bind(this)}>
        <input autoFocus='true' placeholder='Neue Einsatzbezeichnung eingeben...' name='operationName' />
      </form>)
    }

    let editButtonClass = classNames({
      'edit': 'edit',
      'invisible': this.state.editName
    })

    let cancelButtonClass = classNames({
      'cancel': 'cancel',
      'invisible': !this.state.editName
    })

    return (
      <div>
        <Header />
        <div className='operationdetail'>
          <div className='header'>
            <Link to='/'><div className='back' /></Link>
            <div className='info'>
              <span className='name'>#<FormattedNumber value={operation.serial} format='serial' /> - {operationName}</span><br />
              <span className='date'><FormattedDate value={operation.startDate} format='short' /> - <FormattedTime value={operation.startDate} format='hhmmss' /></span>
            </div>
            <div className={editButtonClass}>
              <SquareButton onClick={this.toggleEdit.bind(this)} active={false}>
                <Icon icon='fa-pencil' />
              </SquareButton>
            </div>
            <div className={cancelButtonClass}>
              <SquareButton onClick={this.toggleEdit.bind(this)} active={false}>
                <Icon icon='fa-times' />
              </SquareButton>
            </div>
            {endOperationButton}
            <div className='icons'>
              <div className='item'>
                <IconWithCounter icon='fa-camera' counter={images.length} />
              </div>
              <div className='item share'>
                <IconWithCounter icon='fa-share' counter={numberOfCommentedImages} />
              </div>
            </div>
          </div>
          <div className='imagerow' id='imagerow'>
            <Row renderItem={this.renderImages.bind(this)}
              items={images}
              emptyLabel={`Keine Bilder vorhanden`} />
          </div>
        </div>
      </div>
    )
  }
}

OperationDetailPage.propTypes = {
  id: PropTypes.string.isRequired,
  operation: PropTypes.object,
  loadOperation: PropTypes.func.isRequired,
  updateOperationName: PropTypes.func.isRequired,
  endOperation: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  const route = utils.parseUrl(state.router.location.pathname)
  const anchor = state.router.location.hash.substring(1)
  const opid = route['operations']
  var orgid = route['organisations']
  if (orgid === undefined || orgid === '') {
    orgid = state.application.user.organisation.id.toString()
  }
  const {
    entities: { operations }
  } = state
  const operation = operations[opid]
  const allImages = Object.keys(state.entities.images).map(key => state.entities.images[key])
  const images = allImages.filter((image) => (image.operationId === parseInt(opid, 10))).sort((a, b) => (b.recievingTime - a.recievingTime))
  return {
    orgid,
    'id': opid,
    operation,
    images,
    anchor
  }
}

export default connect(mapStateToProps, {
  loadOperation,
  updateOperationName,
  endOperation
})(OperationDetailPage)
