import React from 'react'
import PropTypes from 'prop-types'

const Media = function (props) {
  const isImage = function (contentType) {
    return contentType === 'image/jpeg' || contentType === 'image/png'
  }

  const isMovie = function (contentType) {
    return contentType === 'video/mp4' || contentType === 'video/h264'
  }

  const {id, recievingTime, contentType} = props.media

  if (isImage(contentType)) {
    return (<img className='media' src={`/api/images/${id}`} alt={recievingTime} />)
  } else if (isMovie(contentType)) {
    return (<video className='media' controls>
      <source src={`/api/images/${id}`} type='video/mp4' />
      Your browser does not support the video tag.
    </video>)
  } else {
    return (<div>Unknown media type</div>)
  }
}

export default Media

Media.propTypes = {
  media: PropTypes.object.isRequired
}
