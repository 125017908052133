import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { loadOperations } from '../actions'
import Operation from '../components/operation/Operation'
import List from '../components/List'
import isEqual from 'lodash/isEqual'
import * as utils from '../utils/utils'
import '../styles/main.css'

class OperationsPage extends Component {
  constructor (props) {
    super(props)
    this.renderOperation = this.renderOperation.bind(this)
    this.handleLoadMoreClick = this.handleLoadMoreClick.bind(this)
    // init websocket connection here
    let host = window.location.host ? window.location.host : 'localhost:3000'
    let protocol = utils.isSecure(window.location.href) ? 'wss://' : 'ws://'
    this.websocket = new WebSocket(protocol + host + '/ws/organisations/' + props.orgid)

    this.websocket.onerror = function (errorEvent) {
      // TODO: Errorhandling
      console.log('Error! Die Verbindung wurde unerwartet geschlossen')
      console.log(errorEvent)
    }

    this.websocket.onclose = function (closeEvent) {
      console.log('Closed the websocket --- Code: ' + closeEvent.code + ' --- Reason: ' + closeEvent.reason)
    }

    this.websocket.onmessage = function (messageEvent) {
      // not the best version, but sufficent at the moment
      props.loadOperations(props.orgid)
    }
  }

  componentDidMount () {
    this.props.loadOperations(this.props.orgid)
  }

  componentDidUpdate (prevProps) {
    if (!isEqual(prevProps.operations, this.props.operations)) {
      this.props.loadOperations(this.props.orgid)
    }
  }

  componentWillReceiveProps (nextProps) {
    if (!isEqual(nextProps.operations, this.props.operations)) {
      this.props.loadOperations(this.props.orgid)
    }
  }

  handleLoadMoreClick () {

  }

  renderOperation (operation) {
    return (<Operation orgid={this.props.orgid} operation={operation} key={operation.id} />)
  }

  render () {
    const { operations } = this.props
    if (!operations) {
      return (<i>Lade Einsatzübersicht...</i>)
    }

    return (
      <div className='operations'>
        <h2>Einsätze</h2>
        <List renderItem={this.renderOperation}
          items={operations}
          onLoadMoreClick={this.handleLoadMoreClick}
          loadingLabel={`Lade Einsätze`} />
      </div>
    )
  }
}

OperationsPage.propTypes = {
  orgid: PropTypes.string.isRequired,
  operations: PropTypes.array.isRequired,
  loadOperations: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  const route = utils.parseUrl(state.router.location.pathname)
  var orgid = route['organisations']
  if (orgid === undefined || orgid === '') {
    orgid = state.application.user.organisation.id.toString()
  }

  const {
    entities: { operations }
  } = state
  const operationsArray = Object.keys(operations).map(key => operations[key]).sort((op1, op2) => { return op2.startDate - op1.startDate })

  return {
    'orgid': orgid,
    'operations': operationsArray
  }
}

export default connect(mapStateToProps, {
  loadOperations
})(OperationsPage)
