import React, {Component} from 'react'
import {connect} from 'react-redux'
import { redirect } from '../actions'

export default function requireAuthentication (ComposedClass) {
  class AuthenticatedComponent extends Component {

    componentWillMount () {
      this.checkAuth()
    }

    componentWillReceiveProps (nextProps) {
      this.checkAuth()
    }

    checkAuth () {
      if (!this.props.token) {
        // redirect to login and add next param so we can redirect again after login
        // this.props.redirect(`/login?next=${this.props.location.pathname}`)
        this.props.redirect('/login')
      }
    }

    render () {
        // render the component that requires auth (passed to this wrapper)
      return (
        <div>
          {this.props.token ? <ComposedClass {...this.props} /> : null }
        </div>
      )
    }
  }

  const mapStateToProps =
    (state) => ({
      token: state.application.token
    })

  return connect(mapStateToProps, {redirect})(AuthenticatedComponent)
}
