import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { doLogin, LOGIN_SUCCESS, redirect, resetErrorMessage } from '../../actions'
import './Login.css'

class Login extends Component {
  constructor (props) {
    super(props)
    this.state = {
      username: '',
      password: ''
    }
  }

  // This will be called when the user clicks on the login button
  login (evt) {
    evt.preventDefault()
    const result = this.props.doLogin(this.state)
    result.then(value => {
      if (value.type === LOGIN_SUCCESS) {
        this.props.resetErrorMessage()
        this.props.redirect('/')
      }
    })
  }

  handleInputChange (evt) {
    this.setState({
      [evt.target.name]: evt.target.value
    })
  }

  render () {
    const { errorMessage } = this.props
    return (
      <div>
        <div className='logo' />
        <div className='loginbox'>
          <h2>Login</h2>
          <b className='error'>{errorMessage}</b>
          <form onSubmit={this.login.bind(this)} onChange={this.handleInputChange.bind(this)}>
            <div className='form_group'>
              <label htmlFor='username'>Benutzername:</label> <input autoCapitalize='off' autoCorrect='off' id='username' type='text' name='username' placeholder='Benutzername' />
            </div>
            <div className='form_group'>
              <label htmlFor='password'>Passwort:</label><input id='password' type='password' name='password' placeholder='Passwort' />
            </div>
            <button type='submit'>anmelden</button>
          </form>
        </div>
      </div>
    )
  }
}

Login.propTypes = {
  errorMessage: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string,
  doLogin: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    errorMessage: state.errorMessage
  }
}

export default connect(mapStateToProps, {
  doLogin,
  redirect,
  resetErrorMessage
})(Login)
