import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {FormattedNumber, FormattedDate, FormattedTime} from 'react-intl'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { loadImage, doTogglePublished } from '../actions'
import * as utils from '../utils/utils'
import CommentList from '../components/comment/CommentList'
import SquareButton from '../components/squareButton/SquareButton'
import IconWithCounter from '../components/iconWithCounter/IconWithCounter'
import Media from '../components/media/Media'
import Icon from '../components/icon/Icon'
import '../styles/main.css'

class ImageDetailPage extends Component {
  constructor (props) {
    super(props)
    this.state = {commentsVisible: false}
  }

  componentDidMount () {
    this.props.loadImage(this.props.orgid, this.props.opid, this.props.imageid)
  }

  togglePublish (published, evt) {
    evt.preventDefault()
    this.props.doTogglePublished(this.props.orgid, this.props.opid, parseInt(evt.currentTarget.value, 10), {
      'published': !published
    })
  }

  toggleComments () {
    if (this.state.commentsVisible) {
      this.setState({commentsVisible: false})
    } else {
      this.setState({commentsVisible: true})
    }
  }

  render () {
    if (!this.props.image) {
      return (<div>Loading...</div>)
    }

    const {id, recievingTime, recordingTime, serial, cameraName, published} = this.props.image
    const commentsListClass = classNames('comments', {'on': this.state.commentsVisible})

    return (
      <div className='imagedetail'>
        <div className='main'>
          <div className='header'>
            <Link to={`/organisations/${this.props.orgid}/operations/${this.props.opid}#image-${id}`}><div className='back' /></Link>
            <div className='infoAndMeta'>
              <span className='info'>Bild <FormattedNumber value={serial} format='serial' /> - {cameraName}</span><br />
              <strong className='meta'><FormattedTime value={recordingTime} format='hhmmss' /></strong> - <FormattedDate value={recordingTime} format='short' /> (Empfangen: <FormattedTime value={recievingTime} format='hhmmss' /> - <FormattedDate value={recievingTime} format='short' />)
            </div>
            <div className='icons'>
              <SquareButton value={id} onClick={this.togglePublish.bind(this, published)} active={published} comment={false} cssInActive invisible>
                <Icon icon='fa-share' />
              </SquareButton>
              <SquareButton onClick={this.toggleComments.bind(this)} active={this.state.commentsVisible} comment cssInActive={false} invisible={false}>
                <IconWithCounter icon='fa-comments-o' counter={this.props.commentCount} />
              </SquareButton>
            </div>
          </div>
          <Media media={this.props.image} />
        </div>
        <div className={commentsListClass}><CommentList imageId={id} recordingTime={recordingTime} /></div>
      </div>
    )
  }
}

ImageDetailPage.propTypes = {
  orgid: PropTypes.string.isRequired,
  opid: PropTypes.string.isRequired,
  imageid: PropTypes.number.isRequired,
  image: PropTypes.object,
  loadImage: PropTypes.func.isRequired,
  doTogglePublished: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  const route = utils.parseUrl(state.router.location.pathname)
  const opid = route['operations']
  const imageid = parseInt(route['images'], 10)
  var orgid = route['organisations']
  if (orgid === undefined || orgid === '') {
    orgid = state.application.user.organisation.id.toString()
  }
  const image = state.entities.images[imageid]
  const commentCount = Object.values(state.entities.comments).filter(element => { return element['imageId'] === imageid }).length
  return {
    'orgid': orgid,
    'opid': opid,
    'imageid': imageid,
    'image': image,
    'commentCount': commentCount
  }
}

export default connect(mapStateToProps, {
  loadImage,
  doTogglePublished
})(ImageDetailPage)
