import { push } from 'react-router-redux'
import { CALL_API, Schemas } from '../middleware/api'

export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE'

// Resets the currently visible error message.
export function resetErrorMessage () {
  return {
    type: RESET_ERROR_MESSAGE
  }
}

// Load operations
export const OPERATIONS_REQUEST = 'OPERATIONS_REQUEST'
export const OPERATIONS_SUCCESS = 'OPERATIONS_SUCCESS'
export const OPERATIONS_FAILURE = 'OPERATIONS_FAILURE'

function fetchOperations (id) {
  const call = {
    [CALL_API]: {
      types: [ OPERATIONS_REQUEST, OPERATIONS_SUCCESS, OPERATIONS_FAILURE ],
      endpoint: `organisations/${id}/operations`,
      schema: Schemas.OPERATION_ARRAY
    }
  }
  return call
}

export function loadOperations (id, requiredFields = []) {
  return (dispatch, getState) => {
    return dispatch(fetchOperations(id))
  }
}

// Load a single operation
export const OPERATION_REQUEST = 'OPERATION_REQUEST'
export const OPERATION_SUCCESS = 'OPERATION_SUCCESS'
export const OPERATION_FAILURE = 'OPERATION_FAILURE'

function fetchOperation (orgid, opid) {
  const call = {
    [CALL_API]: {
      types: [ OPERATION_REQUEST, OPERATION_SUCCESS, OPERATION_FAILURE ],
      endpoint: `organisations/${orgid}/operations/${opid}`,
      schema: Schemas.OPERATION
    }
  }
  return call
}

export function loadOperation (orgid, opid) {
  return (dispatch, getState) => {
    return dispatch(fetchOperation(orgid, opid))
  }
}

export const UPDATE_OPERATION_NAME_REQUEST = 'UPDATE_OPERATION_NAME_REQUEST'
export const UPDATE_OPERATION_NAME_SUCCESS = 'UPDATE_OPERATION_NAME_SUCCESS'
export const UPDATE_OPERATION_NAME_FAILURE = 'UPDATE_OPERATION_NAME_FAILURE'

function doUpdateOperationName (orgid, opid, operation) {
  const call = {
    [CALL_API]: {
      types: [ UPDATE_OPERATION_NAME_REQUEST, UPDATE_OPERATION_NAME_SUCCESS, UPDATE_OPERATION_NAME_FAILURE ],
      endpoint: `organisations/${orgid}/operations/${opid}`,
      schema: Schemas.OPERATION,
      method: 'PUT',
      body: operation
    }
  }
  return call
}

export function updateOperationName (orgid, opid, operation) {
  return (dispatch, getState) => {
    return dispatch(doUpdateOperationName(orgid, opid, operation))
  }
}

// End a single operation
export const END_OPERATION_REQUEST = 'END_OPERATION_REQUEST'
export const END_OPERATION_SUCCESS = 'END_OPERATION_SUCCESS'
export const END_OPERATION_FAILURE = 'END_OPERATION_FAILURE'

function doEndOperation (orgid, opid) {
  const call = {
    [CALL_API]: {
      types: [ END_OPERATION_REQUEST, END_OPERATION_SUCCESS, END_OPERATION_FAILURE ],
      endpoint: `organisations/${orgid}/operations/${opid}/end`,
      schema: Schemas.OPERATION,
      method: 'PUT'
    }
  }
  return call
}

export function endOperation (orgid, opid) {
  return (dispatch, getState) => {
    return dispatch(doEndOperation(orgid, opid))
  }
}

// Login api
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

function login (credentials) {
  const call = {
    [CALL_API]: {
      types: [ LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE ],
      endpoint: `users/login`,
      schema: Schemas.CREDENTIAL,
      method: 'POST',
      body: credentials
    }
  }
  return call
}

export function doLogin (credentials) {
  return (dispatch, getState) => {
    return dispatch(login(credentials))
  }
}

// logout api
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

function logout () {
  const call = {
    [CALL_API]: {
      types: [ LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE ],
      endpoint: `users/logout`,
      schema: Schemas.CREDENTIAL,
      method: 'GET'
    }
  }
  return call
}

export function doLogout () {
  return (dispatch, getState) => {
    return dispatch(logout())
  }
}

// image api
export const IMAGE_REQUEST = 'IMAGE_REQUEST'
export const IMAGE_SUCCESS = 'IMAGE_SUCCESS'
export const IMAGE_FAILURE = 'IMAGE_FAILURE'

function fetchImage (orgid, opid, id) {
  const call = {
    [CALL_API]: {
      types: [ IMAGE_REQUEST, IMAGE_SUCCESS, IMAGE_FAILURE ],
      endpoint: `organisations/${orgid}/operations/${opid}/images/${id}`,
      schema: Schemas.IMAGE
    }
  }
  return call
}

export function loadImage (orgid, opid, id, requiredFields = []) {
  return (dispatch, getState) => {
    return dispatch(fetchImage(orgid, opid, id))
  }
}

// comment api
export const COMMENT_SAVE_REQUEST = 'COMMENT_SAVE_REQUEST'
export const COMMENT_SAVE_SUCCESS = 'COMMENT_SAVE_SUCCESS'
export const COMMENT_SAVE_FAILURE = 'COMMENT_SAVE_FAILURE'
export const COMMENT_ADD = 'COMMENT_ADD'

function saveComment (comment) {
  const call = {
    [CALL_API]: {
      types: [ COMMENT_SAVE_REQUEST, COMMENT_SAVE_SUCCESS, COMMENT_SAVE_FAILURE ],
      endpoint: `comments/`,
      schema: Schemas.COMMENT,
      method: 'POST',
      body: comment
    }
  }
  return call
}

export function doSaveComment (comment) {
  return (dispatch, getState) => {
    return dispatch(saveComment(comment))
  }
}

export function addComment (comment) {
  return {
    type: COMMENT_ADD,
    comment
  }
}

// published api
export const TOGGLE_PUBLISHED_REQUEST = 'TOGGLE_PUBLISHED_REQUEST'
export const TOGGLE_PUBLISHED_SUCCESS = 'TOGGLE_PUBLISHED_SUCCESS'
export const TOGGLE_PUBLISHED_FAILURE = 'TOGGLE_PUBLISHED_FAILURE'

function togglePublished (orgid, opid, imageId, published) {
  const call = {
    [CALL_API]: {
      types: [ TOGGLE_PUBLISHED_REQUEST, TOGGLE_PUBLISHED_SUCCESS, TOGGLE_PUBLISHED_FAILURE ],
      endpoint: `organisations/${orgid}/operations/${opid}/images/${imageId}`,
      schema: Schemas.IMAGE,
      method: 'PUT',
      body: published
    }
  }
  return call
}

export function doTogglePublished (orgid, opid, imageId, published) {
  return (dispatch, getState) => {
    return dispatch(togglePublished(orgid, opid, imageId, published))
  }
}

// Load a single organisation
export const ORGANISATION_REQUEST = 'ORGANISATION_REQUEST'
export const ORGANISATION_SUCCESS = 'ORGANISATION_SUCCESS'
export const ORGANISATION_FAILURE = 'ORGANISATION_FAILURE'

function fetchOrganisation (id) {
  const call = {
    [CALL_API]: {
      types: [ ORGANISATION_REQUEST, ORGANISATION_SUCCESS, ORGANISATION_FAILURE ],
      endpoint: `organisations/${id}`,
      schema: Schemas.ORGANISATION
    }
  }
  return call
}

export function loadOrganisation (id, requiredFields = []) {
  return (dispatch, getState) => {
    const organisation = getState().entities.organisations[id]
    if (organisation && requiredFields.every(key => organisation.hasOwnProperty(key))) {
      return null
    }
    return dispatch(fetchOrganisation(id))
  }
}

// Load all  organisations
export const ORGANISATIONS_REQUEST = 'ORGANISATIONS_REQUEST'
export const ORGANISATIONS_SUCCESS = 'ORGANISATIONS_SUCCESS'
export const ORGANISATIONS_FAILURE = 'ORGANISATIONS_FAILURE'

function fetchOrganisations () {
  const call = {
    [CALL_API]: {
      types: [ ORGANISATIONS_REQUEST, ORGANISATIONS_SUCCESS, ORGANISATIONS_FAILURE ],
      endpoint: `organisations`,
      schema: Schemas.ORGANISATION_ARRAY
    }
  }
  return call
}

export function loadOrganisations (requiredFields = []) {
  return (dispatch, getState) => {
    return dispatch(fetchOrganisations())
  }
}

// redirect

export function redirect (path) {
  return (dispatch, getState) => {
    return dispatch(push(path))
  }
}
