import requireAuthentication from './components/AuthenticatedComponent'
import requireAuthorization from './components/AuthorizedComponent'
import Logout from './components/Logout'
import OperationDetailPage from './containers/OperationDetailPage'
import OperationsPage from './containers/OperationsPage'
import ImageDetailPage from './containers/ImageDetailPage'
import LoginPage from './containers/LoginPage'
import Dashboard from './containers/Dashboard'

const routes = [
  { path: '/',
    exact: true,
    component: requireAuthentication(Dashboard)
  },
  { path: '/organisations/:orgid/operations/:opid/images/:imgid',
    component: requireAuthorization(ImageDetailPage, ['firefighter', 'consultant'])
  },
  { path: '/organisations/:orgid/operations/:opid',
    component: requireAuthorization(OperationDetailPage, ['firefighter', 'consultant'])
  },
  { path: '/organisations/:id',
    component: requireAuthorization(OperationsPage, ['consultant'])
  },
  { path: '/login',
    component: LoginPage
  },
  { path: '/logout',
    component: Logout
  }
]

export default routes
