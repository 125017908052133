import React from 'react'
import PropTypes from 'prop-types'
import './Row.css'

const Row = function (props) {
  const {items, renderItem, emptyLabel} = props
  const isEmpty = items.length === 0
  if (isEmpty) {
    return (<h2><i>{emptyLabel}</i></h2>)
  }

  return (
    <div className='row' >
      {items.map(renderItem)}
    </div>
  )
}

export default Row

Row.propTypes = {
  emptyLabel: PropTypes.string.isRequired,
  renderItem: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired
}

Row.defaultProps = {
  emptyLabel: 'Nothing here.'
}
