import React from 'react'
import PropTypes from 'prop-types'
import './IconWithCounter.css'
import classNames from 'classnames'

const IconWithCounter = function (props) {
  const icons = classNames('fa', props.icon)
  return (<div className='iconWithCounter_iconWithCounter'><i className={icons} aria-hidden='true' /><div className='iconWithCounter_counter'>{props.counter}</div></div>)
}

export default IconWithCounter

IconWithCounter.propTypes = {
  icon: PropTypes.string.isRequired,
  counter: PropTypes.number.isRequired
}
