import React, { Component } from 'react'
import fetch from 'isomorphic-fetch'
import './Backup.css'

export class Backup extends Component {
  constructor () {
    super()
    this.state = {
      backup: false
    }
  }

  componentDidMount () {
    fetch('/api/health/backup', {})
      .then(response =>
        response.text().then(text => ({ text, response }))
      ).then((result) => this.toggleBackup(result))
  }

  toggleBackup (result) {
    if(result.text === 'true'){
      this.setState({ 'backup': result.text === 'true' })
    }
  }

  render () {
    return this.state.backup ? (<div className='corner-ribbon bottom-right sticky red shadow'>Backup System</div>) : null
  }
}
